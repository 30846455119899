/**
 * Handles all font loading separate from main theme so that fonts are not
 * render-blocking
 *
 * Place @font-face directives at the top of this file
 */

// Font Awesome 5 Pro includes & overrides

$fa-font-path: "../fonts";

//@import '//fonts.googleapis.com/css?family=Montserrat:300,400,700,900|Oswald:400,700';

@import "@fortawesome/fontawesome-pro/scss/fontawesome",
        "@fortawesome/fontawesome-pro/scss/solid",
        //"@fortawesome/fontawesome-pro/scss/regular",
        //"@fortawesome/fontawesome-pro/scss/light",
        "@fortawesome/fontawesome-pro/scss/brands";

.fa, .fas, .far, .fal, .fab {

	// While fonts loading
	.wf-loading & {
		// Prevent FOUT
		color: transparent;
	}
}
